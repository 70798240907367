/* ==========================================================================
   #Animation
   ========================================================================== */

/*

    CONTENTS:
    - Variables
    - Animations
    - Keyframes

 */


/* Variables
========================================================================== */

$animate-duration:  1s;
$animate-delay:  .5s;
$animation-ease: '';


/* Animations
========================================================================== */

.animate {
    animation-duration: $animate-duration;
    animation-fill-mode: both;
}

.animate--fast {
    animation-duration: $animate-duration/2;
}

.animate--slow {
    animation-duration: $animate-duration*2;
}

.animate--delay {
    animation-delay: $animate-delay;
}
.animate--delay-double {
    animation-delay: $animate-delay*2;
}
.animate--delay-triple {
    animation-delay: $animate-delay*4;
}

.animate--fade-in {
    animation-name: fade-in;
}

.animate--fade-right {
    animation-name: fade-right;
}

.animate--fade-up {
    animation-name: fade-up;
}

.animate--zoom-out {
    animation-name: zoom-out;
}

.animate--zoom-in {
    animation-name: zoom-in;
}


/* Keyframes
========================================================================== */

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-10px);}
    60% {transform: translateY(-5px);}
}


@keyframes fade-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes fade-right {
    0% {opacity: 0;transform: translateX(-50%);}
    50% {opacity: 0;}
    100% {opacity: 1;transform: translateX(0%);}
}

@keyframes fade-up {
    0% {opacity: 0;transform: translateY(50%);}
    100% {opacity: 1;transform: translateX(0%);}
}

@keyframes flip {
    0% { transform: scaleX(-1); }
    50% { transform: scaleX(1); }
    100% { transform: scaleX(-1); }
}

@keyframes zoom-out {
    0% {opacity: 0;transform: scale(1.2);}
    100% {opacity: 1;transform: scale(1);}
}

@keyframes zoom-in {
    0% {opacity: 0;transform: scale(1);}
    100% {opacity: 1;transform: scale(1.05);}
}