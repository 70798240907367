/* ==========================================================================
   #Logo
   ========================================================================== */

$logo-path:             '../images/logo.png';
$logo-width:            100px;
$logo-height:           120px;
$logo-width--narrow:    50px;
$logo-height--narrow:   41px;


.logo {
    @include notext;
    display: block;

    width: $logo-width;
    height: $logo-height;

    background: url($logo-path) no-repeat center top;
    background-size: 100% auto;

    transition: all .2s;

    @include breakpoint("tablet-down") {
        width: 50px;
        height: 60px;
    }
}


    .logo--narrow,
    .site-head.isSticky .logo {
        width: $logo-width--narrow;
        height: $logo-height--narrow;
    }