/* ==========================================================================
   #Text
   ========================================================================== */


/*

    CONTENTS:
    - Alignment
    - Size
    - Color
    - Transforms

 */



/* Alignment
========================================================================== */

@mixin text-align-types( $breakpoint:"" ) {
    .#{$breakpoint}text-center {
        text-align: center !important;
    }
    .#{$breakpoint}text-right {
        text-align: right !important;
    }
    .#{$breakpoint}text-left {
        text-align: left !important;
    }
}

@include text-align-types();

@each $breakpoint in $breakpoint-has-text-align {
    @if $breakpoint != '' {
        @include breakpoint( $breakpoint ) {
            @include text-align-types( '#{$breakpoint}--' );
        }
    }
}



/* Size
========================================================================== */

@mixin text-size-types( $breakpoint:"" ) {
    @if $font-size-x-large != false {
        .#{$breakpoint}text-x-large {
            @include typeset( $font-size-x-large );
        }
    }

    @if $font-size-large != false {
        .#{$breakpoint}text-large {
            @include typeset( $font-size-large );
        }
    }

    @if $font-size-small != false {
        .#{$breakpoint}text-small {
            @include typeset( $font-size-small );
            line-height: 1.5;
        }
    }

    @if $font-size-x-small != false {
        .#{$breakpoint}text-x-small {
            @include typeset( $font-size-x-small );
        }
    }
}

@include text-size-types();

@each $breakpoint in $breakpoint-has-text-size {
    @if $breakpoint != '' {
        @include breakpoint( $breakpoint ) {
            @include text-size-types( '#{$breakpoint}--' );
        }
    }
}



/* Colors
========================================================================== */

@if $brand-primary != false {
    .text-primary {
        color: $brand-primary;
    }
}

@if $brand-secondary != false {
    .text-secondary {
        color: $brand-secondary;
    }
}

@if $brand-tertiary != false {
    .text-tertiary {
        color: $brand-tertiary;
    }
}

@if $brand-quaternary != false {
    .text-quaternary {
        color: $brand-quaternary;
    }
}

@if $brand-quinary != false {
    .text-quinary {
        color: $brand-quinary;
    }
}

@if $brand-inverted != false {
    .text-inverted {
        color: $brand-inverted;
    }
}



/* Transform
========================================================================== */

.text-uppercase {
    text-transform: uppercase;
}

.text-strong {
    font-weight: 700;
}

.text-semi {
    font-weight: 600;
}

.text-plain {
    font-family: arial;
}