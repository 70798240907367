/* ==========================================================================
#Paragraphs
========================================================================== */

p {
    margin-bottom: rhythm(2);
}

    @if $font-size-large != false {
        .lead {
            @include typeset( 24px );
            line-height: 1.5;
        }
    }

    @if $font-size-small != false {
        .support {
            font-size: $font-size-small;
        }
    }