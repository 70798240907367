/* ==========================================================================
   #Widths
   ========================================================================== */


/*

    CONTENTS:
    - Variables
    - Widths
    - Breakpoint widths

 */


/* Variables
========================================================================== */

$widths:                ('whole', 'half', 'third', 'quarter', 'fifth', 'sixth', 'eighth', 'tenth')!default;
$widths-responsive:     false!default;


/* Widths
========================================================================== */

@mixin width-types( $breakpoint:"" ) {

    @each $width in $widths {

        @if $width == 'whole' {
            .#{$breakpoint}one-whole         { width: 100%; }
        }

        @if $width == 'half' {
            .#{$breakpoint}one-half          { width: 50%; }
        }

        @if $width == 'third' {
            .#{$breakpoint}one-third         { width: 33.333%; }
            .#{$breakpoint}two-thirds        { width: 66.666%; }
        }


        @if $width == 'quarter' {
            .#{$breakpoint}one-quarter       { width: 25%; }
            .#{$breakpoint}three-quarters    { width: 75%; }
        }


        @if $width == 'fifth' {
            .#{$breakpoint}one-fifth         { width: 20%; }
            .#{$breakpoint}two-fifths        { width: 40%; }
            .#{$breakpoint}three-fifths      { width: 60%; }
            .#{$breakpoint}four-fifths       { width: 80%; }
        }


        @if $width == 'sixth' {
            .#{$breakpoint}one-sixth         { width: 16.666%; }
            .#{$breakpoint}five-sixths       { width: 83.333%; }
        }


        @if $width == 'eighth' {
            .#{$breakpoint}one-eighth        { width: 12.5%; }
            .#{$breakpoint}three-eighths     { width: 37.5%; }
            .#{$breakpoint}five-eighths      { width: 62.5%; }
            .#{$breakpoint}seven-eighths     { width: 87.5%; }
        }


        @if $width == 'tenth' {
            .#{$breakpoint}one-tenth         { width: 10%; }
            .#{$breakpoint}three-tenths      { width: 30%; }
            .#{$breakpoint}seven-tenths      { width: 70%; }
            .#{$breakpoint}nine-tenths       { width: 90%; }
        }

    }

}

@include width-types();



/* Breakpoints
  ========================================================================== */

    @if $widths-responsive == true {
        @each $breakpoint in $breakpoint-has-widths {
            @if $breakpoint != '' {
                @include breakpoint( $breakpoint ) {
                    @include width-types( '#{$breakpoint}--');
                }
            }
        }
    }