/* ==========================================================================
   #SLIDER
   ========================================================================== */

.slider {
}

    .slider__slide {
        position: relative;
        width: 100%;

        > img {
            width: 100%;
        }
    }


.slick-dots {
    text-align: center;

    > li {
        display: inline-block;
        margin: 0 rhythm(0.25);

        > button {
            @include notext;

            width: rhythm(0.5);
            height: rhythm(0.5);

            background: $brand-tertiary;
            border: none;
            border-radius: 80%;

            cursor: pointer;
        }

        &.slick-active > button {
            background: $brand-primary;
        }
    }

    @include breakpoint("mobile") {
        margin-top: rhythm(0.5);
    }
}


.slick-prev,
.slick-next {
    @include notext;
    display: block;

    position: absolute;
    top: calc(50% - 25px);

    border: 0;
    background: none;

    width: rhythm(.75);
    height: rhythm(.75);

    z-index: 10;

    cursor: pointer;
}
    .slick-prev {
        left: rhythm(.5);

        border-top: $bd-size solid #FFF;
        border-left: $bd-size solid #FFF;
        transform: rotate(-45deg);
    }
    .slick-next {
        right: rhythm(.5);

        border-top: $bd-size solid #FFF;
        border-right: $bd-size solid #FFF;
        transform: rotate(45deg);
    }