/* ==========================================================================
   #Header
   ========================================================================== */


/*

    CONTENTS:
    - Header
    - Logo / Toggle
    - Nav Primary

 */


/* Header
========================================================================== */

.site-head {
    position: fixed;
    top: 0;
    left: 0; right: 0;
    padding: rhythm(1) 0;

    z-index: 10;

    transition: all .3s;

    &.isSticky {
        padding: rhythm(.5) 0;
        background-color: #FFF;
        box-shadow: 0 0 60px 10px rgba(0,0,0,0.1);
    }
}



/* Logo / Burger
========================================================================== */

.site-head__logo {
    display: inline-block;

    .site-head.isOpen & {
        z-index: 100;
    }
}

.site-head__toggle {
    display: inline-block;

    .site-head.isOpen & {
        z-index: 100;
    }

    @include breakpoint("desk") {
        display: none;
    }
}



/* Nav Primary
========================================================================== */

.nav-primary {
    position: relative;

    @include breakpoint("tablet-down") {
        display: flex;
        align-items: center;
        justify-content: center;

        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        padding: rhythm(1);
        min-height: 100vh;

        background-color: #e4e4e4;

        transform: translateX(-100%);
        transition: transform .4s;

        &.isOpen {
            transform: none;
        }
    }

    @include breakpoint("desk") {

    }
}


.nav-primary__menu {
    text-align: right;

    > li {
        display: inline-block;
        list-style: none;
        margin: 0 rhythm(1);

        &.menu-item-social {
            margin: 0 0 0 rhythm(.5);

            a:after {
                display: none !important;
            }
        }

        > a {
            position: relative;
            font-weight: 600;
            color: $brand-inverted;
            text-transform: uppercase;
            font-family: $base-family--alt;
            letter-spacing: 0.05em;

            .site-head.isSticky & {
                color: $brand-primary;
            }

            &:hover,
            &:focus,
            &.isActive {
                &:after {
                    opacity: 1;
                    transform: none;
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: -5px;
                left: 0px;
                right: 0px;

                height: 4px;
                background-color: $brand-inverted;

                transform: translateY(15px);
                opacity: 0;

                transition: all .4s;

                .site-head.isSticky & {
                    background-color: $brand-primary;
                }
            }

            > i,
            > span {
                @include typeset(22px);
                line-height: 0;
                letter-spacing: 0;
                vertical-align: middle;
            }
        }
    }

    @include breakpoint('mobile') {
        text-align: center;
        > li {
            display: block;
            text-align: center;
            margin: rhythm(1) 0 !important;

            > a {
                @include typeset($font-size-large);

                color: $brand-primary;
            }

            &.menu-item-social {
                display: inline-block;
                text-align: center;
                margin: rhythm(1) rhythm(.5) !important;
            }
        }
    }
}
