/* ==========================================================================
   #LISTS
   ========================================================================== */

.list-default {
    margin: 0 rhythm(1) rhythm(1);

    > li {
        margin-bottom: rhythm(0.5);
    }
}

.list-inline {
    list-style: none;

    > li {
        display: inline;
    }
}

    .list-inline--middle {
        > li {
            vertical-align: middle;
        }
    }

.list-plain {
    list-style: none;
}