/* ==========================================================================
   #Footer
   ========================================================================== */
body {
    padding-bottom: 500px;

    @include breakpoint('mobile') {
        padding-bottom: 300px;
    }
}

.page-main {
    z-index: 1;
    box-shadow: 0 0 60px 10px rgba(0,0,0,0.1);
}

.site-foot {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 0;

    &__contact {
        position: absolute;
        left: rhythm(4);
        top: 50%;
        transform: translateY(-50%);

        padding: rhythm(2) rhythm(2);

        background-color: $brand-primary;
        color: $brand-inverted;

        z-index: 10;

        @include breakpoint('mobile') {
            position: relative;
            left: 0;
            top: 0;
            transform: none;
            height: 300px;
        }
    }

    &__map {
        width: 100%;
        height: 500px;

        @include breakpoint('mobile') {
            display: none;
        }
    }
}