/* ==========================================================================
   #Gutters
   ========================================================================== */


/*

    CONTENTS:
    - Tights
    - Rhythm
    - Gutters

 */


/* Tights
========================================================================== */

/**
 * Gutter Resets
 */

@mixin gutter-tight-types( $breakpoint:"" ) {
    .#{$breakpoint}tight {
        margin: 0 !important;
    }
}

// default
@include gutter-tight-types();

// breakpoints
@each $breakpoint in $breakpoint-has-tights {
    @if $breakpoint != '' {
        @include breakpoint( $breakpoint ) {
            @include gutter-tight-types( '#{$breakpoint}--' );
        }
    }
}



/* Rhythm
========================================================================== */

@mixin rhythm-types( $breakpoint:"" ) {
    @for $i from 1 through $rhythms-max {

        .#{$breakpoint}rhythm-m-#{$i} {
            margin-top: $base-gutter * $i;
            margin-bottom: $base-gutter * $i;
        }

        .#{$breakpoint}rhythm-m-t-#{$i} {
            margin-top: $base-gutter * $i;
        }

        .#{$breakpoint}rhythm-m-b-#{$i} {
            margin-bottom: $base-gutter * $i;
        }

        .#{$breakpoint}rhythm-p-#{$i} {
            padding-top: $base-gutter * $i;
            padding-bottom: $base-gutter * $i;
        }

        .#{$breakpoint}rhythm-p-t-#{$i} {
            padding-top: $base-gutter * $i;
        }

        .#{$breakpoint}rhythm-p-b-#{$i} {
            padding-bottom: $base-gutter * $i;
        }
    }
}

// default
@include rhythm-types();

// breakpoints
@each $breakpoint in $breakpoint-has-rhythm {
    @if $breakpoint != '' {
        @include breakpoint( $breakpoint ) {
            @include rhythm-types( '#{$breakpoint}--' );
        }
    }
}



/* Gutters
========================================================================== */

@mixin gutter-types( $breakpoint:"" ) {
    @for $i from 1 through $gutters-max {

        .#{$breakpoint}gutter-m-#{$i} {
            margin-right: $base-gutter * $i;
            margin-left: $base-gutter * $i;
        }

        .#{$breakpoint}gutter-m-l-#{$i} {
            margin-left: $base-gutter * $i;
        }

        .#{$breakpoint}gutter-m-r-#{$i} {
            margin-right: $base-gutter * $i;
        }

        .#{$breakpoint}gutter-p-#{$i} {
            padding-right: $base-gutter * $i;
            padding-left: $base-gutter * $i;
        }

        .#{$breakpoint}gutter-p-l-#{$i} {
            padding-left: $base-gutter * $i;
        }

        .#{$breakpoint}gutter-p-r-#{$i} {
            padding-right: $base-gutter * $i;
        }
    }
}

// default
@include gutter-types();

// breakpoints
@each $breakpoint in $breakpoint-has-gutter {
    @if $breakpoint != '' {
        @include breakpoint( $breakpoint ) {
            @include gutter-types( '#{$breakpoint}--' );
        }
    }
}