/* ==========================================================================
   #FORMS
   ========================================================================== */


.form { }


.form__fieldset {
    padding: rhythm(1);

    border: 1px solid $bd-color;
    border-radius: $bd-radius;
}


.form__row {
    margin-bottom: rhythm(.5);
}


.form__label {
    display: block;
    margin-bottom: rhythm(.5)
}


.form__input {
    padding: rhythm(0.5);

    border: 1px solid $bd-color;
    border-radius: $bd-radius;

    width: 100%;

    &:focus {
        border: 1px solid $brand-primary;
    }

    &.error {
        border: 1px solid darken(red, 10%);
    }
}

    .form__input--small {
        width: rhythm(4);
        margin-right: rhythm(0.5);
        text-align: center;
    }

    .form__input--select {
        width: auto;
        min-width: 200px;
    }

    .form__input--checkbox {
        margin-left: rhythm(0.2);
        margin-right: rhythm(0.8);
    }


.form__text-area {
    min-height: 100px;
}

