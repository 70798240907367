/* ==========================================================================
#Reset
========================================================================== */


/*

    CONTENTS:
    - Defaults
    - Html
    - Links
    - Typography
    - Tables
    - Media
    = Forms
    - Other

 */


/* Defaults
========================================================================== */

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dt,
dd,
legend,
fieldset,
figure,
address,
blockquote {
    padding: 0;
    margin: 0;
    border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include typeset( $base-font-size );
    font-weight: normal;
}

address {
    font-style: normal;
}



/* HTML
========================================================================== */

* {
    box-sizing: border-box;
}

html {
    font-family: $base-family;
    font-size: $base-font-size / 16px + em;
    line-height: $base-line-height / $base-font-size;
    color: $text-color;
    @include cleartext;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    background: $bg-default;
}



/* Links
========================================================================== */

a {
    background-color: transparent;
}

a[href^=tel]{
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}



/* Typography
========================================================================== */

pre {
    margin: 0;
    overflow: auto;
}

code,
samp,
kbd,
pre {
    line-height: 1;
    font-family: monospace, monospace;
    font-size: 1em;
}

small {
    @include typeset( $base-font-size*0.75 );
    vertical-align: bottom;
}

sup,
sub {
    @include typeset( ( $base-font-size*0.75 ), 0.5 );
}

hr {
    box-sizing: content-box;
    height: 0;
}



/* Tables
========================================================================== */

table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

th,
td {
    padding: 0;
    text-align: left;
}



/* Media
========================================================================== */

img,
svg {
    max-width: 100%;
    vertical-align: bottom;
    height: auto;
    border: 0;
}

audio,
canvas,
progress,
video {
        display: inline-block; /* 1 */
        vertical-align: baseline; /* 2 */
    }

    audio:not([controls]) {
        display: none;
        height: 0;
    }



/* Forms
========================================================================== */

/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
input,
button,
textarea,
select {
    @include typeset( $base-font-size );
    color: inherit; /* 1 */
    font: inherit; /* 2 */
    margin: 0; /* 3 */
    padding: 0;
}

textarea {
    vertical-align: text-top;
    resize: vertical;
    overflow: auto;
}

input[type="image"] {
    border: 0;
}

input[type="checkbox"],
input[type="radio"] {
    vertical-align: baseline;
}


/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
    overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: none; /* 2 */
    cursor: pointer; /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
    line-height: normal;
}

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-input-placeholder {
    color: $text-color;
}
::-moz-placeholder {
    color: $text-color;
}
:-ms-input-placeholder {
    color: $text-color;
}
input:-moz-placeholder {
    color: $text-color;
}


/* Other
========================================================================== */

[hidden],
template {
    display: none;
}

#__bs_notify__ {
    top: auto !important;
    bottom: 0 !important;
    font-size: 12px !important;
}