/* ==========================================================================
#Heading
========================================================================== */


/*

    CONTENTS:
    - Font Sizes
    - Custom

 */


/* Font Sizes
========================================================================== */

@if $h1-size != false {
    h1,
    .h1 {
        @include typeset( $h1-size );
        line-height: 1.2;

        @if $h1-size-desk != false {
            @include breakpoint('desk') {
                @include typeset( $h1-size-desk );
            }
        }
        @if $h1-size-tablet != false {
            @include breakpoint('tablet') {
                @include typeset( $h1-size-tablet );
            }
        }
    }
}

@if $h2-size != false {
    h2,
    .h2 {
        @include typeset( $h2-size );

        @if $h2-size-desk != false {
            @include breakpoint('desk') {
                @include typeset( $h2-size-desk );
            }
        }
        @if $h2-size-tablet != false {
            @include breakpoint('tablet') {
                @include typeset( $h2-size-tablet );
            }
        }
    }
}

@if $h3-size != false {
    h3,
    .h3 {
        @include typeset( $h3-size );

        @if $h3-size-desk != false {
            @include breakpoint('desk') {
                @include typeset( $h3-size-desk );
            }
        }
        @if $h3-size-tablet != false {
            @include breakpoint('tablet') {
                @include typeset( $h3-size-tablet );
            }
        }
    }
}

@if $h4-size != false {
    h4,
    .h4 {
        @include typeset( $h4-size );

        @if $h4-size-desk != false {
            @include breakpoint('desk') {
                @include typeset( $h4-size-desk );
            }
        }
        @if $h4-size-tablet != false {
            @include breakpoint('tablet') {
                @include typeset( $h4-size-tablet );
            }
        }
    }
}

@if $h5-size != false {
    h5,
    .h5 {
        @include typeset( $h5-size );

        @if $h5-size-desk != false {
            @include breakpoint('desk') {
                @include typeset( $h5-size-desk );
            }
        }
        @if $h5-size-tablet != false {
            @include breakpoint('tablet') {
                @include typeset( $h5-size-tablet );
            }
        }
    }
}

@if $h6-size != false {
    h6,
    .h6 {
        @include typeset( $h6-size );

        @if $h6-size-desk != false {
            @include breakpoint('desk') {
                @include typeset( $h6-size-desk );
            }
        }
        @if $h6-size-tablet != false {
            @include breakpoint('tablet') {
                @include typeset( $h6-size-tablet );
            }
        }
    }
}



/* Custom
========================================================================== */

h1,
.h1 {
    font-family: $base-family--alt;
    text-transform: uppercase;
    font-weight: 700;

    margin-bottom: rhythm(1);
}

h2,
.h2 {
    font-family: $base-family--alt;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: rhythm(.5);
}

h3,
.h3 {
    font-family: $base-family--alt;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: rhythm(.5);
}

h4,
.h4 {
    font-family: $base-family--alt;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: rhythm(.5);
}
