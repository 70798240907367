/* ==========================================================================
   #Panels
   ========================================================================== */

.panel {
    position: relative;
    margin-bottom: rhythm(8);

    @include breakpoint('mobile') {
        margin-bottom: 0;
    }

    &__image {
        width: 85%;
        margin-left: 15%;

        @include breakpoint('mobile') {
            width: 90%;
            margin-left: 10%;
        }
    }

    &__body {
        position: absolute;
        bottom: rhythm(3) * -1;
        left: rhythm(1) * -1;
        width: 80%;

        padding: rhythm(1) rhythm(1);

        background-color: rgba($brand-primary, 1);
        color: $brand-inverted;

        p:last-child {
            margin-bottom: 0;
        }

        @include breakpoint('mobile') {
            width: 90%;
            position: relative;
            bottom: auto;
            top: rhythm(2) * -1;
        }
    }
}