/* ==========================================================================
#Backgrounds
========================================================================== */

.bg-default {
    background-color: $bg-default;
}

@if $brand-primary != false {
    .bg-primary {
        background-color: $brand-primary;
    }
}

@if $brand-secondary != false {
    .bg-secondary {
        background-color: $brand-secondary;
    }
}

@if $brand-tertiary != false {
    .bg-tertiary {
        background-color: $brand-tertiary;
    }
}

@if $brand-quaternary != false {
    .bg-quaternary {
        background-color: $brand-quaternary;
    }
}

@if $brand-quinary != false {
    .bg-quinary {
        background-color: $brand-quinary;
    }
}

@if $brand-inverted != false {
    .bg-inverted {
        background-color: $brand-inverted;
    }
}

@if $brand-alt != false {
    .bg-alt {
        background-color: $brand-alt;
    }
}