/* ==========================================================================
   #Mixins
   ========================================================================== */

/*
 * Mixin for Typeset lineheight
 * @include typeset;
*/
@mixin typeset( $font-size, $line-height: true ) {
    font-size: $font-size;
    font-size: ($font-size / $base-font-size) * 1rem;

    @if $line-height == true {
        line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
    }

}



/*
 * Mixin for breakpoints
 * @include breakpoint;
*/
@mixin breakpoint( $media-query ) {
    $breakpoint-found: false;

    @each $breakpoint in $breakpoints {
        $name: nth($breakpoint, 1);
        $declaration: nth($breakpoint, 2);

        @if $media-query == $name and $declaration {
            $breakpoint-found: true;

            @media only screen and #{$declaration} {
                @content;
            }
        }
    }

    @if $breakpoint-found == false{
        @warn "Breakpoint ‘#{$media-query}’ does not exist";
    }
}



/*
 * Mixin for Text Legibility
 * @include cleartext;
*/
@mixin cleartext {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}



/*
 * Mixin for Word Wrap
 * @include wordwrap;
*/
@mixin wordwrap {
    word-break: break-word;
    hyphens: auto;
}



/*
 * Mixin for Ellipsis overflow
 * @include ellipsis;
*/
@mixin ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}



/*
 * Mixin for Hide text (alternative to using the old "-9999em" method)
 * @include notext;
*/
@mixin notext {
    font: 0/0 a;
    color: transparent;
}



/*
 * Mixin for clearfix
 * @include clearfix;
*/
@mixin clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}



/*
 * Mixin for font family stack
 * @include font-face("Font Name", '../fonts/path-to-font-with-name', 'weight');
*/
@mixin fontface($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}



/*
 * Mixin for Font awesome Icon
 * @include iconify('unicode');
 * @desc requires Font Awesome https://fortawesome.github.io/Font-Awesome/
*/
@mixin iconify( $unicode ) {
    &:before {
        content: $unicode;
        display: inline-block;
        font-family: 'FontAwesome';
        font-size: inherit;
        font-weight: normal;
        color: inherit;
        vertical-align: middle;
    }
}



/*
 * Mixin for basic CSS triangles
 * @include triangle(up, #000, 50px)
*/
@mixin triangle($direction:up, $color:#000, $size:100px) {
    @if($direction == up) {
        border-color: transparent transparent $color;
        border-style: solid;
        border-width: 0 $size $size;
        height: 0;
        width: 0;
    }
    @if($direction == down) {
        border-color: $color transparent transparent transparent;
        border-style: solid;
        border-width: $size;
        height:0;
        width:0;
    }
    @if($direction == left) {
        border-color: transparent $color transparent transparent;
        border-style: solid;
        border-width: $size $size $size 0;
        height: 0;
        width: 0;
    }
    @if($direction == right) {
        border-color: transparent transparent transparent $color;
        border-style: solid;
        border-width: $size 0 $size $size;
        height:0;
        width:0;
    }
}



/*
 * @font-face mixin
 * Bulletproof font-face via Font Squirrel
 * @include fontface('family', 'assets/fonts/', 'myfontname');
 */
@mixin fontfacebasic($font-family, $font-url, $font-name) {
    @font-face {
        font: {
            family: $font-family;
            style: normal;
            weight: normal;
        }
        src: url($font-url + '/' + $font-name + '.eot');
        src: url($font-url + '/' + $font-name + '.eot#iefix') format('embedded-opentype'),
             url($font-url + '/' + $font-name + '.woff') format('woff'),
             url($font-url + '/' + $font-name + '.ttf')  format('truetype'),
             url($font-url + '/' + $font-name + '.svg#' + $font-name) format('svg');
    }
}



/* ==========================================================================
   #FUNCTIONS
   ========================================================================== */


/*
 * Function for rhythm
 * @desc gives vertical rhythm values based on base gutter
 * @include rhythm;
*/
@function rhythm($lines: 1) {
    @return floor( $lines * $base-gutter );
}


/*
 * Function for string replace
*/
@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}