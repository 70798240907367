/* ==========================================================================
   #BANNER
   ========================================================================== */

$banner-height:         700px;
$banner-height-narrow:  300px;
$banner-bg:             #000;
$banner-opacity:        .1;


.banner {
    position: relative;
    display: flex;

    width: 100%;
    height: $banner-height;

    @include breakpoint("tablet-down") {
        height: $banner-height-narrow;
    }
}

    .banner--narrow {
        height: $banner-height-narrow;
    }

    .banner--full {
        height: 100vh;

        @include breakpoint("tablet-down") {
            height: 90vh;
        }
    }

    .banner--cover {
        background-size: cover;
        background-position: top center;
    }

    .banner--opaque {
        position: relative;
        z-index: 0;

        &:after {
            content: '';

            position: absolute;
            top: 0; bottom: 0;
            left: 0; right: 0;

            background: $banner-bg;

            opacity: $banner-opacity;

            z-index: -1;
        }
    }

    @include breakpoint("desk") {
        .banner--fixed {
            background-attachment: fixed;
        }
    }

.banner__body {
    width: 100%;
    margin: auto;
    padding-top: rhythm(6);

    color: $brand-inverted;

    @include breakpoint('mobile') {
        padding-top: rhythm(4);
    }
}

.banner__arrow {
    display: block;
    position: absolute;
    bottom: rhythm(1);
    left: 50%;

    width: rhythm(2);
    margin-left: -25px;

    @include typeset($font-size-large);
    line-height: 1;
    text-align: center;

    color: $brand-inverted;

    animation: bounce 1.4s infinite;
}