/* ==========================================================================
   #Burger
   ========================================================================== */

$burgerWidth:   40px;
$burgerBar:     5px;
$burgerGap:     6px;
$burgerBg:      $brand-primary;


.burger {
   position: relative;
    width: $burgerWidth;
    height: ($burgerBar*2) + ($burgerGap * 2);

    &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

    .burger__filling,
    .burger__filling:before,
    .burger__filling:after {
        display: block;
        width: 100%;
        height: $burgerBar;
        background: $burgerBg;
        transition: all 0.5s;
        backface-visibility: hidden;
    }

        .burger__filling {
            position: relative;
            margin: $burgerBar+$burgerGap 0 0;
        }

        .burger__filling:before,
        .burger__filling:after {
            position: absolute;
            content: "";
        }

        .burger__filling:before {
            top: ($burgerBar + $burgerGap) * -1;
        }

        .burger__filling:after {
            top: $burgerBar + $burgerGap;
        }


    // open burger
    .burger.isOpen {

        .burger__filling {
            transform: rotate(45deg);

            &:before {
                transform: translate(0px, ($burgerBar + $burgerGap)) rotate(-90deg);
            }
            &:after {
                transform: translate(0px, -($burgerBar + $burgerGap)) rotate(-90deg);
            }
        }
    }
