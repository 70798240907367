/* ==========================================================================
   #Grids
   ========================================================================== */


/*

    CONTENTS:
    - Variables
    - Layout
    - Types

 */



/* Variables
   ========================================================================== */

$grid-gutter:       24px!default;
$grid-spaces:       ('full', 'half', 'double', 'tripe', 'quad')!default;
$grid-directions:   ('rev', 'right', 'center', 'middle', 'bottom')!default;



/* Layout
   ========================================================================== */

.grid {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-left: -$grid-gutter;
}

    .grid__item {
        display: inline-block;
        padding-left: $grid-gutter;
        vertical-align: top;
        width: 100%;
    }



/* Types
   ========================================================================== */

@each $direction in $grid-directions {

    @if $direction == 'rev' {
        .grid--rev {
            direction: rtl;
            text-align: left;

            > .grid__item {
                direction: ltr;
                text-align: left;
            }
        }
    }

    @if $direction == 'right' {
        .grid--right {
            text-align:right;

            > .grid__item {
                text-align: left;
            }
        }
    }

    @if $direction == 'center' {
        .grid--center {
            text-align: center;

            > .grid__item {
                text-align: left;
            }
        }
    }

    @if $direction == 'middle' {
        .grid--middle {
            > .grid__item {
                vertical-align: middle;
            }
        }
    }

    @if $direction == 'bottom' {
        .grid--bottom {
            > .grid__item {
                vertical-align: bottom;
            }
        }
    }

}


@each $space in $grid-spaces {

    @if $space == 'full' {
        .grid--full {
            margin-left: 0;

            > .grid__item {
                padding-left: 0;
            }
        }
    }

    @if $space == 'half' {
        .grid--half {
            margin-left: -($grid-gutter / 2);

            > .grid__item {
                padding-left: $grid-gutter / 2;
            }
        }
    }

    @if $space == 'double' {
        .grid--double {
            margin-left: -($grid-gutter * 2);

            > .grid__item {
                padding-left: $grid-gutter * 2;
            }
        }
    }

    @if $space == 'triple' {
        .grid--triple {
            margin-left: -($grid-gutter * 3);

            > .grid__item {
                padding-left: $grid-gutter * 3;
            }
        }
    }

    @if $space == 'quad' {
        .grid--quad {
            margin-left: -($grid-gutter * 4);

            > .grid__item {
                padding-left: $grid-gutter * 4;
            }
        }
    }

}