/* ==========================================================================
   #Wrappers
   ========================================================================== */


.site-wrapper {

}

    // Sticky footer
    // Flexbox method for IE10+
    .site-wrapper--flex {
        display: flex;
        min-height: 100vh;
        flex-direction: column;

        .page-main {
            flex: 1;

            .no-flexboxlegacy &,
            .no-flexbox & {
                flex: none;
            }
        }
    }


.page-main { }


.post {

    &__images {
        img {
            width: 100%;
        }
    }
}


/**
 *
 * CMS
 *
 */

.cms-body {
    ol,ul {
        margin: 0 0 rhythm(1) rhythm(2);
    }
    p:last-child {
        margin-bottom: 0;
    }
}
