/* ==========================================================================
   #VARIABLES
   ========================================================================== */


/*

    CONTENTS:
    - Base
    - Breakpoints
    - Modules
    - Theme
    - Library

*/


/* Base
   ========================================================================== */

    $base-font-size:        17px;
    $base-line-height:      28px;
    $base-family:           'Open Sans', sans-serif;
    $base-family--alt:      'Poppins', sans-serif;

    $base-gutter:           20px;

    $widths:                ('whole', 'half', 'third', 'quarter', 'fifth', 'sixth', 'eighth', 'tenth')!default;
    $widths-responsive:     true;

    $rhythms-max:           6;
    $gutters-max:           4!default;


/* Break points
   ========================================================================== */

    $page-max:              1200px;
    $page-max--narrow:      1020px;
    $page-max--wide:        false!default;

    $mobile-min:            320px;
    $mobile-max:            600px;
    $tablet-min:            $mobile-max + 1;
    $tablet-max:            820px;
    $desk-min:              $tablet-max + 1;

    $breakpoints: (
        'mobile' '(max-width: #{$mobile-max})',
        'tablet-down' '(max-width: #{$tablet-max})',
        'tablet' '(min-width: #{$tablet-min}) and (max-width: #{$tablet-max})',
        'tablet-up' '(min-width: #{$tablet-min})',
        'desk' '(min-width: #{$desk-min})'
    );

    $breakpoint-has-widths: ('tablet', 'desk')!default;
    $breakpoint-has-display:('tablet', 'desk')!default;
    $breakpoint-has-hidden: ('tablet', 'desk')!default;
    $breakpoint-has-text-align: ('tablet', 'desk')!default;
    $breakpoint-has-text-size: ('tablet', 'desk')!default;
    $breakpoint-has-tights: ('tablet', 'desk')!default;
    $breakpoint-has-rhythm: ('desk', 'mobile')!default;
    $breakpoint-has-gutter: ('desk')!default;



/* Modules
   ========================================================================== */

    $grid-gutter:           $base-gutter;
    $grid-spaces:           ('full', 'half', 'double', 'triple', 'quad');
    $grid-directions:       ('rev', 'right', 'center', 'middle', 'bottom');



/* Theme
   ========================================================================== */

    $h1-size:               32px;
    $h2-size:               46px;
    $h3-size:               22px;
    $h4-size:               20px;
    $h5-size:               false!default;
    $h6-size:               false!default;

    $h1-size-tablet:        54px;
    $h2-size-tablet:        false!default;
    $h3-size-tablet:        false!default;
    $h4-size-tablet:        false!default;
    $h5-size-tablet:        false!default;
    $h6-size-tablet:        false!default;

    $h1-size-desk:          54px;
    $h2-size-desk:          false!default;
    $h3-size-desk:          false!default;
    $h4-size-desk:          false!default;
    $h5-size-desk:          false!default;
    $h6-size-desk:          false!default;

    $font-size-x-large:     false!default;
    $font-size-large:       22px;
    $font-size-small:       14px;
    $font-size-x-small:     false!default;

    $text-color:            #242424;

    $link-color:            $text-color!default;
    $link-color-hover:      darken($link-color, 10%);

    $brand-primary:         #4a8096;
    $brand-secondary:       #666;
    $brand-tertiary:        false!default;
    $brand-quaternary:      false!default;
    $brand-quinary:         false!default;
    $brand-inverted:        #FFF;
    $brand-alt:             #f2f4f4;

    $bg-default:            #FFF;

    $bd-color:              #000;
    $bd-radius:             2px;
    $bd-size:               1px;

    $btn-border:            false!default;
    $btn-bg:                false!default;
    $btn-types:             ('primary', 'secondary', 'tertiary', 'inverted');



/* Library Overrides
   ========================================================================== */

    $slick-font-path:     "";
    $slick-font-family:   "Arial";
    $slick-loader-path:   "";

    $fa-font-path:        "../fonts";