/* ==========================================================================
   #LIGHTBOX
   ========================================================================== */

$lightbox-width:        600px;
$lightbox-height:       auto;
$lightbox-bg:           $bg-default;


.lightbox-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background: #000;
    background: rgba(0, 0, 0, 0.9);

    z-index: 1010;
}


.lightbox {
    position: absolute;
    top: 50%;
    left: 50%;

    width: $lightbox-width;
    height: $lightbox-height;

    transform: translate(-50%, -50%);

    padding: rhythm(1);

    background: $lightbox-bg;


    @media screen and (max-width: ($lightbox-width + $base-gutter)) {
        width: calc(100% -  #{$base-gutter});
    }
}