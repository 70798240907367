/* ==========================================================================
   #LAYOUT HELPERS
   ========================================================================== */


/*

    CONTENTS:
    - Content Wrappers
    - Block Display

 */


/* Content Wrappers
   ========================================================================== */

.contented {
    margin: 0 auto;
    max-width: $page-max;

    @media screen and (max-width: ($page-max + ($base-gutter*2))) {
        max-width: 100%;
        margin: 0 rhythm(1);
    }
}

    @if $page-max--narrow != false {
        .contented-narrow {
            margin: 0 auto;
            max-width: $page-max--narrow;

            @media screen and (max-width: ($page-max--narrow + ($base-gutter*2))) {
                max-width: 100%;
                margin: 0 rhythm(1);
            }
        }
    }

    @if $page-max--wide != false {
        .contented-wide {
            margin: 0 auto;
            max-width: $page-max--wide;

            @media screen and (max-width: ($page-max--wide + ($base-gutter*2))) {
                max-width: 100%;
                margin: 0 rhythm(1);
            }
        }
    }



/* Block Display
   ========================================================================== */

.block {
    display: block !important;
}

    @each $breakpoint in $breakpoint-has-display {
        @if $breakpoint != '' {
            @include breakpoint( $breakpoint ) {
                .#{$breakpoint}--block {
                    display: block !important;
                }
            }
        }
    }


.hidden {
    display: none !important;
}

    @each $breakpoint in $breakpoint-has-hidden {
        @if $breakpoint != '' {
            @include breakpoint( $breakpoint ) {
                .#{$breakpoint}--hidden {
                    display: none !important;
                }
            }
        }
    }