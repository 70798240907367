/* ==========================================================================
#Links
========================================================================== */

a {
    color: $link-color;
    text-decoration: none;

    transition: color .2s linear;
}

    a:hover {
        color: $link-color-hover;
        text-decoration: none;
    }

a.link-inverted {
    color: $brand-inverted;

    &:hover {
        color: $brand-inverted;
    }
}

a.link-icon {
    > i {
        margin-right: rhythm(.25);
        vertical-align: middle;
    }
}