/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.btn {
    display: inline-block;
    padding: rhythm( .75 ) rhythm( 1.5) rhythm( .6 );

    @include typeset($font-size-small);
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $base-family--alt;
    letter-spacing: 0.1em;

    cursor: pointer;

    transition: all .1s linear;

    background: none;

    @if $btn-border != false {
        border: $bd-size solid;
    }

    &:hover {
        transition: all .1s linear;
    }
}

.btn--primary {
    background-color: $brand-primary;
    color: $brand-inverted;
    border-bottom: 4px solid darken($brand-primary, 20%);

    &:hover {
        background-color: darken($brand-primary, 10%);
        color: $brand-inverted;
    }
}

.btn--inverted {
    background-color: $brand-inverted;
    color: $brand-primary;
    border-bottom: 4px solid darken($brand-inverted, 20%);

    &:hover {
        background-color: darken($brand-inverted, 10%);
        color: $brand-primary;
    }
}
